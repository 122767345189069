"use strict";

var body = document.querySelector("body");

function debounce(func, wait, immediate) {
	if (!func) return;
	var timeout;
	return function executedFunction() {
		var context = this;
		var args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
}

window.onload = () => body.classList.add("page-loaded");

$(function () {
	window.autodealer = {};

	window.autodealer.form = {
		checkForm: function checkForm(form) {
			var checkResult = true;
			form.find(".error").removeClass("error");
			form.find("input, textarea, select").each(function () {
				if ($(this).data("req")) {
					switch ($(this).data("type")) {
						case "phone":
							var re =
								/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
							if (!re.test($(this).val())) {
								$(this).parent().addClass("error");
								checkResult = false;
							}
							break;
						case "email":
							var re =
								/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
							if (!re.test($(this).val())) {
								$(this).parent().addClass("error");
								checkResult = false;
							}
							break;
						default:
							if ($(this).val().trim() === "") {
								$(this).parent().addClass("error");
								checkResult = false;
							}
							break;
					}
				}
			});
			return checkResult;
		},

		init: function init() {
			var _th = this;

			$("form").on("submit", function () {
				if (!_th.checkForm($(this))) {
					return false;
				}
			});

			if ($(".js-phone").length) {
				$(".js-phone").mask("+7(999) 999-9999");
			}

			if ($(".js-time").length) {
				$(".js-time").mask("99:99");
			}

			return this;
		},
	}.init();

	window.autodealer.obj = {
		nav: function nav() {
			var nav = $(".js-nav");
			var navToggle = $(".js-nav-toggle");
			var navList = nav.find("> .nav__list");
			var navSecondLevel = nav.find(".nav__second-level");
			var navSecondList = navSecondLevel.find(".nav__list");
			var navSecondListToggle = navList.find(".nav__list-item--button");

			var lastWidthX = window.innerWidth;

			if (navSecondLevel.length && navSecondList.length && navToggle.length) {
				navToggle.on("click", function (e) {
					e.preventDefault();
					$(this).toggleClass("active");
					navSecondLevel.toggleClass("show");
				});

				var navDebounce = debounce(function () {
					var navListItems = navList.find(
						".nav__list-item:not(.nav__list-item--button)"
					);
					var navSecondListItem = navSecondList.find(".nav__list-item");

					var diffWidthX = window.innerWidth - lastWidthX;
					var widthNav = parseInt(nav.width() - 58, 10);
					var widthNavItems = 0;

					lastWidthX = window.innerWidth;

					if (navListItems.length) {
						var indexLastItem = navListItems.length;
						for (var i = 0; i < navListItems.length; i++) {
							widthNavItems += parseInt(navListItems[i].offsetWidth, 10);
							if (widthNav < widthNavItems) {
								indexLastItem = i;
								break;
							}
						}

						for (var y = navListItems.length - 1; y >= indexLastItem; y--) {
							navSecondList.prepend(navListItems[y]);
						}
					}

					if (diffWidthX > 0 && navSecondListItem.length) {
						for (var z = 0; z < navSecondListItem.length; z++) {
							widthNavItems += parseInt(navSecondListItem[z].offsetWidth, 10);
							if (widthNav > widthNavItems) {
								navList.append(navSecondListItem[z]);
							}
						}
					}

					if (navSecondList.children().length) {
						navSecondListToggle.fadeIn(0);
					} else {
						navSecondListToggle.fadeOut(0);
					}
				}, 250);

				$(window).on("resize", navDebounce);
			}
		},

		burger: function burger() {
			const burger = $(".js-burger");
			const navMobile = $(".nav-mobile");
			const closeNav = $(".js-close-nav");
			const backNav = $(".js-back-nav");
			const btnShowSublist = $(".js-show-sublist");

			if (burger.length) {
				burger.on("click", function () {
					navMobile.fadeIn(350);
					$(body).addClass("open-mobile-menu");
					return false;
				});
			}

			if (closeNav.length) {
				closeNav.on("click", function () {
					navMobile.fadeOut(350);
					$(body).removeClass("open-mobile-menu");
					return false;
				});
			}

			if (btnShowSublist.length) {
				btnShowSublist.on("click", function () {
					$(this).siblings(".nav-mobile__sublist").addClass("show");
					backNav.addClass("show");
					return false;
				});
			}

			if (backNav.length) {
				backNav.on("click", function () {
					$(".nav-mobile__sublist.show").removeClass("show");
					backNav.removeClass("show");
					return false;
				});
			}
		},

		yaMap: function yaMap() {
			var map = $(".js-yaMap");
			var coords = map.data("coords").split(",");

			ymaps.ready(function () {
				var myMap = new ymaps.Map("yaMap", {
					center: [coords[0], coords[1]],
					zoom: map.data("zoom") || 14,
					controls: ["largeMapDefaultSet"],
				});

				myMap.controls.add("zoomControl", {
					size: "small",
				});

				myMap.behaviors.disable("scrollZoom");

				var myPlacemark = new ymaps.Placemark(
					coords,
					{},
					{
						iconLayout: "default#image",
						iconImageHref: "static/images/pin.png",
						iconImageSize: [47, 54],
					}
				);

				myMap.geoObjects.add(myPlacemark);
			});

			return;
		},

		jqMfp: function mfp() {
			$(".js-mfp").magnificPopup({
				type: "inline",
				mainClass: "mfp-fade",
				closeOnBgClick: false,
				callbacks: {
					beforeOpen: function () {
						var activeItemIndex = this.index;
						if (
							this.items[activeItemIndex] &&
							this.items[activeItemIndex].getAttribute("href") === "#tooltip"
						) {
							var text = this.items[activeItemIndex].dataset.mfpContent;
							if ($(".popup--tooltip .popup__text").length && text) {
								$(".popup--tooltip .popup__text").text(text);
							}
						}
					},
				},
			});

			$(".js-mfp-close").on("click", function (e) {
				$.magnificPopup.close();
				e.preventDefault();
			});
		},

		jqVideoMfp: function jqVideoMfp() {
			$(".js-video-mfp").magnificPopup({
				disableOn: 700,
				type: "iframe",
				mainClass: "mfp-fade",
				closeOnBgClick: false,
				removalDelay: 160,
				preloader: false,
				fixedContentPos: false,
				disableOn: 0,
				callbacks: {
					open: function () {
						$("body").addClass("open-video-popup");
					},
					close: function () {
						$("body").removeClass("open-video-popup");
					},
				},
			});
		},

		jqMfpGallery: function jqMfpGallery() {
			$(".js-mfp-gallery").magnificPopup({
				delegate: "a",
				type: "image",
				tLoading: "Loading image #%curr%...",
				mainClass: "mfp-img",
				gallery: {
					enabled: true,
					navigateByImgClick: true,
					preload: [0, 1],
				},
			});
		},

		init: function init() {
			const _self = this;

			const headerBottom = $(".header__bottom");
			const btnUp = $(".js-scroll-up");
      const toggleVisibleBtnOnScroll = $(".js-toggle-visible-btn-on-scroll");

			if ($(".js-nav").length) {
				_self.nav();
			}

			if ($(".js-burger").length) {
				_self.burger();
			}

			if ($(".js-yaMap").length) {
				_self.yaMap();
			}

			if ($(".js-mfp").length) {
				_self.jqMfp();
			}

			if ($(".js-video-mfp").length) {
				_self.jqVideoMfp();
			}

			if ($(".js-mfp-gallery").length) {
				_self.jqMfpGallery();
			}

			if ($(".js-select").length) {
				$(".js-select").styler({
					selectSmartPositioning: false,
				});
			}

			if ($("a[rel=tipsy]").length) {
				$("a[rel=tipsy]").tipsy({ fade: true, gravity: "n" });
			}

			if (
				$(".section-history__item-gallery").length &&
				$(".section-history__item-gallery .js-history-gallery-big").length &&
				$(".section-history__item-gallery .js-history-gallery-thumbs").length
			) {
				$(".section-history__item-gallery").each(function (index, item) {
					new Swiper($(item).find(".js-history-gallery-big"), {
						spaceBetween: 9,
						loop: true,
						navigation: {
							nextEl: $(item).find(".swiper-button-next"),
							prevEl: $(item).find(".swiper-button-prev"),
						},
						thumbs: {
							swiper: {
								el: $(item).find(".js-history-gallery-thumbs"),
								spaceBetween: 10,
								slidesPerView: 4,
								watchSlidesVisibility: true,
								watchSlidesProgress: true,
								slideToClickedSlide: true,
								loop: true,
							},
						},
					});
				});
			}

			if ($(".js-specialists-tabs").length) {
				var tabsBtn = $(
						".js-specialists-tabs .specialists__tabs-controls-button"
					),
					tabsImg = $(".js-specialists-tabs .specialists__tabs-img"),
					tabrepairtoyotam = $(".js-specialists-tabs .specialists__tabs-item");

				function actionsClasses(elRemoveClass, elAddClass) {
					elRemoveClass.removeClass("active");
					elAddClass.addClass("active");
				}
				tabsBtn.on("click", function (e) {
					var _t = $(this),
						_tData = _t.data("tabs-btn");
					if (
						$(e.target).closest(".specialists__tabs-controls-button").length &&
						$(e.target).hasClass("specialists__tabs-controls-href")
					) {
						return;
					}
					if (
						!_t.hasClass("active") &&
						$(
							'.js-specialists-tabs .specialists__tabs-item[data-tabs-item="' +
								_tData +
								'"]'
						).length
					) {
						actionsClasses(tabsBtn, _t);
						actionsClasses(
							tabsImg,
							$(
								'.js-specialists-tabs .specialists__tabs-img[data-tabs-img="' +
									_tData +
									'"]'
							)
						);
						actionsClasses(
							tabrepairtoyotam,
							$(
								'.js-specialists-tabs .specialists__tabs-item[data-tabs-item="' +
									_tData +
									'"]'
							)
						);
					}
					return false;
				});
			}

			if ($(".js-toggle-analogues").length) {
				$(".js-toggle-analogues").on("click", function (e) {
					var _t = $(this);
					var _tDefaultText = _t.data("text-btn");
					var _tParent = _t.parents(".calculator__table-row-group");
					var _tDropdown = _tParent.find(".calculator__table-row-group");
					if (_tDropdown.length) {
						if (_t.hasClass("active")) {
							_t.removeClass("active").html(
								"<span>" + _tDefaultText + "</span>"
							);
							_tDropdown.fadeOut(350);
						} else {
							_t.addClass("active").html(
								"<span>Свернуть список аналогов</span>"
							);
							_tDropdown.fadeIn(350);
						}
					}
					e.preventDefault();
				});
			}

			if ($("[data-anchor-btn]").length) {
				$("[data-anchor-btn]").on("click", function (e) {
					var _t = $(this);
					var _tData = _t.data("anchor-btn");

					function scrollToAnchor(offset) {
						if ($('[data-anchor-item="' + _tData + '"]').length) {
							$("html, body").animate(
								{
									scrollTop:
										$('[data-anchor-item="' + _tData + '"]').offset().top -
										offset,
								},
								800
							);
						}
					}

					if ($(window).width() < 768) {
						$(".js-close-nav").trigger("click");
						setTimeout(function () {
							scrollToAnchor(45);
						}, 350);
					} else {
						scrollToAnchor(60);
					}

					e.preventDefault();
				});
			}

			if ($(".ripplelink").length) {
				$(".ripplelink").each(function () {
					var $this = $(this);
					var ink, d, x, y;
					setInterval(function () {
						if ($this.find(".ink").length === 0) {
							$this.prepend("<span class='ink'></span>");
						}

						ink = $this.find(".ink");
						ink.removeClass("animate");

						if (!ink.height() && !ink.width()) {
							d = Math.max($this.outerWidth(), $this.outerHeight());
							ink.css({
								height: d,
								width: d,
							});
						}

						x = Math.round(Math.random() * ink.width() - ink.width() / 2);
						y = Math.round(Math.random() * ink.height() - ink.height() / 2);
						ink
							.css({
								top: y + "px",
								left: x + "px",
							})
							.addClass("animate");
					}, 1000);
				});
			}

			if ($(".js-toggle-discount").length) {
				$(".js-toggle-discount").on("change", function () {
					var rowDiscount = $(this)
						.parents(".calculator")
						.find(".calculator__table-total-row--discount");
					if (rowDiscount.length) {
						if ($(this).is(":checked")) {
							rowDiscount.fadeIn(300).css("display", "flex");
							return;
						}
						rowDiscount.fadeOut(300);
					}
				});
			}

			btnUp.on("click", function () {
        $("html, body").animate(
          {
            scrollTop: 0,
          },
          1200
        );
        return false;
      });

			$(window).on("scroll", function () {
				const scrollTop = $(window).scrollTop();
				const windowHeight = $(window).height();

				if (headerBottom.length) {
					if (scrollTop >= headerBottom.offset().top) {
						if (!headerBottom.hasClass("header__bottom--fixed")) {
							headerBottom.addClass("header__bottom--fixed");
						}
					} else if (headerBottom.hasClass("header__bottom--fixed")) {
						headerBottom.removeClass("header__bottom--fixed");
					}
				}

				if (scrollTop > windowHeight) {
					if (!toggleVisibleBtnOnScroll.hasClass("show")) {
						toggleVisibleBtnOnScroll.addClass("show");
					}
				} else {
					toggleVisibleBtnOnScroll.removeClass("show");
				}
			});

			$(window).trigger("scroll").trigger("resize");

			$(document).on("click", function (e) {
				if (
					!$(e.target).closest(".nav").length &&
					$(".nav__second-level").hasClass("show")
				) {
					$(".nav__list-button").removeClass("active");
					$(".nav__second-level").removeClass("show");
					e.stopPropagation();
				}
			});

			return this;
		},
	}.init();
});

jQuery.migrateMute = true;

